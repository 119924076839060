<template>
  <div class="dashboard bg-black">
    <div class="mainContent gap-4 justify-items-center text-center">
      <div class="flex flex-col justify-center items-center pt-8">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
          class="w-1/3"
        />
        <div class="font-ZuumeSemiBold text-nColorYellow pt-2 text-5xl my-2">
          REPORTS
        </div>
        <div v-if="emailSection">
          <input
            type="email"
            name="email"
            ref="email"
            id="email"
            v-model="email"
            placeholder="Email"
            class="textInput mt-2"
            required
          />
        </div>
        <div v-if="otpSection">
          <div class="font-InterRegular text-xs mx-8 mt-4 text-white">
            Enter the 4-digit OTP that we have sent to
            <span class="font-InterMedium"
              ><strong>{{ email }}</strong></span
            >
            <div
              class="flex gap-2 font-ManropeMedium mt-4 text-xs text-nColorOrange justify-center"
            >
              <span class="text-gray-400">Wrong email?</span>
              <button @click="enableEmail" class="underline font-bold">
                Change email
              </button>
            </div>
          </div>
          <div class="flex justify-center gap-4 mt-4">
            <input
              v-for="i in 4"
              :key="i"
              @keydown.space.prevent=""
              maxlength="1"
              class="otpText"
              type="number"
              @input="handleInput(i, $event)"
              @keydown.delete="movePrev(i)"
              :ref="'otp' + i"
            />
          </div>
          <div class="flex gap-4 justify-center">
            <div class="font-ManropeMedium mt-4 text-sm">
              <button :disabled="resendDisabled" @click="resendOTP">
                <span
                  :class="{
                    'text-gray-400': resendDisabled,
                    'text-nColorOrange underline font-bold': !resendDisabled,
                  }"
                >
                  Resend OTP
                </span>
                <span v-if="resendDisabled" class="text-gray-400"
                  >({{ countdown }})</span
                >
              </button>
            </div>
          </div>
        </div>
        <div class="w-full text-center">
          <div class="text-sm inline-block mt-2 h-4">
            <div v-if="errorMsg" class="error text-red-600 rounded">
              <p class="text-sm">{{ errorMsg }}</p>
            </div>
            <div v-if="successMsg" class="success text-green-700">
              <p class="text-sm">
                {{ successMsg }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-section absolute text-center w-full">
        <div class="flex justify-center pr-6 relative">
          <div class="absolute bottom-14">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/assets/reports/login_illustration.webp"
              v-if="emailSection"
              width="240"
              height="240"
            />
          </div>
          <div class="button absolute bottom-1 w-full flex justify-center pl-6">
            <button
              @click.prevent="validateEmail"
              :class="[
                'shadow-orange-right bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isLoading },
              ]"
              v-if="emailSection"
              :disabled="isLoading"
            >
              <span v-if="isLoading">
                <svg
                  width="120"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> GET OTP </span>
            </button>
            <button
              @click.prevent="validateOTP()"
              :class="[
                'shadow-orange-right bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isSubmitLoading },
              ]"
              v-if="otpSection"
              :disabled="isSubmitLoading"
            >
              <span v-if="isSubmitLoading">
                <svg
                  width="120"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> SUBMIT </span>
            </button>
          </div>
        </div>
        <div class="text-center mt-1 px-8 text-sm text-nColorSubTextGray">
          Copyright 2023 Texperia Techsolutions Pvt Ltd
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: null,
      errorMsg: null,
      successMsg: null,
      emailSection: true,
      otpSection: false,
      resendDisabled: false,
      countdown: 60,
      isLoading: false,
      isSubmitLoading: false,
      otp: "",
    };
  },
  created() {},
  mounted() {},
  computed: {
    calculatedBottom() {
      const screenHeight = window.innerHeight;
      if (screenHeight < 650) {
        return "0.7";
      } else {
        return "1";
      }
    },
  },
  methods: {
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `otp${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateOTPValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `otp${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateOTPValue() {
      const otpInputValues = Array.from(
        document.querySelectorAll(".otpText")
      ).map((input) => input.value);

      this.otp = otpInputValues.join("");
    },
    enableEmail() {
      const otpElements = document.querySelectorAll(".otpText");

      otpElements.forEach((element) => {
        element.value = ""; // Clear the value
      });
      this.emailSection = true;
      this.otpSection = false;
    },
    resendOTP() {
      ApiService.post(apiResource.resendOTP, {
        business_slug: this.$route.params.business,
        email: this.email,
      })
        .then(() => {
          this.$refs["otp1"][0].focus();
          this.startResendCounter();
        })
        .catch(() => {});
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    validateEmail() {
      this.isLoading = true;
      this.errorMsg = "";
      if (this.email) {
        ApiService.post(apiResource.login, {
          business_slug: this.$route.params.business,
          email: this.email,
        })
          .then(() => {
            this.isLoading = false;
            this.emailSection = false;
            this.otpSection = true;
            this.startResendCounter();
          })
          .catch((error) => {
            this.isLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isLoading = false;
        this.errorMsg = "Please enter your email";
      }
    },
    validateOTP() {
      this.isSubmitLoading = true;
      this.errorMsg = "";
      if (this.email && this.otp) {
        this.$store
          .dispatch("validateOTP", {
            email: this.email,
            business_slug: this.$route.params.business,
            otp: this.otp,
          })
          .then(() => {
            this.isSubmitLoading = false;
            this.goToDashboard();
          })
          .catch(({ error }) => {
            this.isSubmitLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isSubmitLoading = false;
        this.errorMsg = "Please enter the OTP";
      }
    },
    goToDashboard() {
      this.$router.replace({
        name: "Dashboard",
        params: {
          business: this.$route.params.business,
        },
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
.hyperlink {
  @apply text-nColorOrange;
}
.textInput {
  @apply text-white border border-nColorYellow rounded-lg block appearance-none focus:bg-nColorOrange focus:outline-none bg-transparent w-80 py-2.5 px-2 placeholder-nColorYellow text-xs font-InterMedium;
}
.otpText {
  @apply bg-nColorYellow rounded font-ZuumeSemiBold text-black border border-black text-3xl focus:outline-none focus:bg-nColorOrange text-center w-10 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled {
  @apply bg-nColorDarkGray;
  pointer-events: none;
}
svg {
  width: 35px;
  height: 35px;
  display: inline-block;
}
.bottom-section {
  @apply bottom-10;
}
@media (min-height: 650px) {
  .bottom-section {
    @apply bottom-16;
  }
}
</style>
